import { LOCALE_CODES } from '@/constants/variables';

export const routes = {
  // about
  about: {
    name: 'about',
    url: '/about',
  },
  company: {
    name: 'company',
    url: '/about/company',
  },
  processingOfPersonalData: {
    name: 'processingOfPersonalData',
    url: '/about/processing-of-personal-data',
  },
  assessmentOfWorkspaces: {
    name: 'assessmentOfWorkspaces',
    url: '/about/assessment-of-workplaces',
  },
  officialSites: {
    name: 'officialSites',
    url: '/about/official_sites',
  },
  esg: {
    name: 'esg',
    url: '/ustoychivoe-razvitie',
  },
  investorRelations: {
    name: 'investorRelations',
    url: '/about/investor-relations',
  },
  antiCorruption: {
    name: 'antiCorruption',
    url: '/about/corporate_governance/trust-line',
  },
  pciDss: {
    name: 'pciDss',
    url: '/about/sootvetstvie-pci-dss',
  },
  informationTechnologiesActivitiy: {
    name: 'informationTechnologiesActivitiy',
    url: '/about/deyatelnost-v-oblasti-informatsionnykh-tekhnologiy',
  },
  requisites: {
    name: 'requisites',
    url: '/about/contacts/requisites',
  },

  // mir
  mir: {
    name: 'mir',
    url: '/cards-mir',
  },
  security: {
    name: 'security',
    url: '/cards-mir/security',
  },
  operators: {
    name: 'operators',
    url: '/cards-mir/perechen-operatorov-uslug-platezhnoy-infrastruktury',
  },
  terms: {
    name: 'terms',
    url: '/cards-mir/terms-and-tariffs',
  },
  software: {
    name: 'software',
    url: '/cards-mir/soft',
  },
  projectCertificates: {
    name: 'projectCertificates',
    url: '/ecert',
  },
  ofertaCertificates: {
    name: 'ofertaCertificates',
    url: '/cards-mir/certificates',
  },

  // banks
  banks: {
    name: 'banks',
    url: '/banks',
  },
  guides: {
    name: 'guides',
    url: '/banks/guides',
  },
  acquiring: {
    name: 'acquiring',
    url: '/banks/acquiring',
  },
  rules: {
    name: 'rules',
    url: '/banks/rules',
  },
  analytics: {
    name: 'analytics',
    url: '/banks/analytics',
  },
  payment: {
    name: 'payment-request',
    url: '/banks/payment-request',
  },
  echeck: {
    name: 'echeck',
    url: '/echeck',
  },
  advertising: {
    name: 'advertising',
    url: '/advertising',
  },

  // knowledge
  knowledge: {
    name: 'knowledge',
    url: '/knowledge',
  },
  knowledgeAbout: {
    name: 'knowledgeAbout',
    url: '/knowledge',
  },
  knowledgeEvents: {
    name: 'knowledgeEvents',
    url: '/knowledge/events',
  },
  knowledgeEvent: {
    name: 'knowledgeEvent',
    url: '/knowledge/events/eventSlug',
  },
  knowledgeEventForm: {
    name: 'knowledgeEventForm',
    url: '/knowledge/events/eventSlug/form',
  },

  // vacancies
  vacancies: {
    name: 'vacancies',
    url: '/vacancies',
  },
  vacanciesForm: {
    name: 'vacanciesForm',
    url: '/vacancies/form',
  },
  vacanciesVacancy: {
    name: 'vacanciesVacancy',
    url: '/vacancies/vacancySlug',
  },
  vacanciesVacancyForm: {
    name: 'vacanciesVacancyForm',
    url: '/vacancies/vacancySlug/form',
  },

  // referral
  referral: {
    name: 'referral',
    url: '/referral',
  },
  referralReferral: {
    name: 'referralReferral',
    url: '/referral/referralSlug',
  },
  referralReferralForm: {
    name: 'referralReferralForm',
    url: '/referral/referralSlug/form',
  },

  // press
  pressCenter: {
    name: 'pressCenter',
    url: '/press-center',
  },
  pressCenterArticle: {
    name: 'pressCenterArticle',
    url: '/press-center/details/articleSlug',
  },

  // contacts
  contacts: {
    name: 'contacts',
    url: '/contacts',
  },

  // search
  search: {
    name: 'search',
    url: '/search',
  },

  // conference
  conference: {
    name: 'conference',
    url: '/tk',
  },
};

export const menu = {
  [LOCALE_CODES.ru]: [
    {
      ...routes.about,
      submenu: [
        routes.company,
        routes.processingOfPersonalData,
        routes.assessmentOfWorkspaces,
        routes.officialSites,
        routes.investorRelations,
        routes.esg,
        routes.antiCorruption,
        routes.pciDss,
        routes.informationTechnologiesActivitiy,
        routes.requisites,
      ],
    },
    {
      ...routes.mir,
      submenu: [
        routes.security,
        routes.operators,
        routes.terms,
        routes.software,
        routes.projectCertificates,
        routes.ofertaCertificates,
      ],
    },
    {
      ...routes.banks,
      submenu: [
        routes.guides,
        routes.acquiring,
        routes.rules,
        routes.analytics,
        routes.echeck,
        routes.advertising,
        routes.payment,
      ],
    },
    {
      ...routes.knowledge,
      submenu: [
        routes.knowledgeAbout,
        routes.knowledgeEvents,
        {
          name: 'knowledgeCourses',
          url: 'https://nspk.ispringlearn.ru/signup/E-bvH9KypJlk7O8o-6Is3pdpjuI',
          target: '_blank',
        },
      ],
    },
    routes.vacancies,
    routes.pressCenter,
    routes.contacts,
  ],
  [LOCALE_CODES.en]: [
    {
      ...routes.about,
      submenu: [
        routes.processingOfPersonalData,
        routes.investorRelations,
        routes.pciDss,
      ],
    },
    {
      ...routes.mir,
      submenu: [routes.terms],
    },
    routes.pressCenter,
    routes.contacts,
  ],
};

const enRouteUrls = [
  routes.about.url,
  routes.processingOfPersonalData.url,
  routes.investorRelations.url,
  routes.pciDss.url,

  routes.mir.url,
  routes.terms.url,

  routes.pressCenter.url,
  // routes.pressCenterArticle.url,

  routes.contacts.url,

  routes.search.url,
];
export const enRouteNames = enRouteUrls.map((routeUrl) =>
  routeUrlToName(routeUrl),
);

function routeUrlToName(routeUrl) {
  return routeUrl
    .split('/')
    .filter((string) => string)
    .join('-');
}

export const linksConference = {
  about: {
    name: 'about',
    url: '/tk#about',
  },
  program: {
    name: 'program',
    url: '/tk#program',
  },
  registration: {
    name: 'registration',
    url: '/tk#registration',
  },
  contacts: {
    name: 'contacts',
    url: '/tk#contacts',
  },
};
export const menuConference = [
  linksConference.about,
  linksConference.program,
  linksConference.registration,
  linksConference.contacts,
];
